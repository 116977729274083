<template>
  <div class="class-manage" v-loading="pageLoading">
    <div class="class-manage-title">
      <div class="text">
        <span>班级管理</span>
        <div class="line"></div>
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      style="margin-top: 3.17708vw"
      :model="searchForm"
      onsubmit="return false"
      :inline="true"
    >
      <el-row>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select
              class="form-input"
              v-model="searchForm.organization"
              clearable
              placeholder="所属组织"
            >
              <el-option
                v-for="item in organizationOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select
              class="form-input"
              v-model="searchForm.classSchool"
              clearable
              placeholder="所属学校"
            >
              <el-option
                v-for="item in schoolOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-select
              class="form-input"
              v-model="searchForm.classGrade"
              clearable
              placeholder="请选择年级"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="form-search-item">
            <el-input
              class="form-input"
              v-model="searchForm.classInfo"
              placeholder="班级名称/编号"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-refresh-right" @click="resetFun"
              >重置</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" icon="el-icon-plus" @click="handleAdd"
              >新建班级</el-button
            > </el-form-item
          >
        </el-col>
      </el-row>
    </el-form>
    <div class="table-content">
      <l-table
        :columns="columns"
        :dataSource="tableData"
        :pagination="pageFlag"
        :ipagination="page"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :loading="tableLoading"
        :showXh="false"
        :autoHeight="true"
      >
        <el-table-column
          prop="organization"
          label="所属组织"
          align="center"
          slot="organization"
        ></el-table-column>
        <el-table-column
          prop="schoolName"
          label="所属学校"
          align="center"
          slot="schoolName"
        ></el-table-column>
        <el-table-column
          prop="gradeName"
          label="年级名称"
          align="center"
          slot="gradeName"
        ></el-table-column>
        <el-table-column
          prop="className"
          label="班级名称"
          align="center"
          width="200"
          slot="className"
        ></el-table-column>
        <el-table-column
          prop="classTeacher"
          label="班主任"
          align="center"
          slot="classTeacher"
        ></el-table-column>
        <el-table-column
          prop="classTeacherNum"
          label="班级老师（数量）"
          align="center"
          slot="classTeacherNum"
        ></el-table-column>
        <el-table-column
          prop="isFinalClass"
          label="毕业班"
          align="center"
          slot="isFinalClass"
        ></el-table-column>
        <el-table-column
          prop="sort"
          label="排序"
          align="center"
          slot="sort"
        ></el-table-column>
        <el-table-column
          prop="status"
          slot="status"
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="handle" align="center" width="200">
          <template slot-scope="scope">
            <span class="table-edit" @click="handleEdit(scope.row)">编辑</span>
            <span class="table-more-edit" @click="handleStepClass(scope.row)">升班</span>
            <span class="table-del" @click="handleDel(scope.row)"
              >删除
            </span>
            <span class="table-more-edit" @click="handleView(scope.row)">详情</span>
          </template>
        </el-table-column>
      </l-table>
    </div>
    <el-dialog
      :title="classDialogTitle"
      :visible.sync="classDialogVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      @before-close="handleClose"
      @close="handleClose"
      width="50%"
    >
      <el-form
        class="dialog-form"
        :model="classDialogForm"
        ref="classDialogForm"
        :rules="rules"
        label-width="6.25vw"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="选择组织："
              prop="organization"
            >
              <el-select
                class="form-input"
                v-model="classDialogForm.organization"
                clearable
                placeholder="请选择组织"
              >
                <el-option
                  v-for="item in organizationOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="选择学校："
              prop="schoolName"
            >
              <el-select
                class="form-input"
                v-model="classDialogForm.schoolName"
                clearable
                placeholder="请选择学校"
              >
                <el-option
                  v-for="item in schoolOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="选择年级："
              prop="gradeName"
            >
              <el-select
                class="form-input"
                v-model="classDialogForm.gradeName"
                clearable
                placeholder="请选择年级"
              >
                <el-option
                  v-for="item in gradeOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="班级名称："
              prop="className"
            >
              <el-input
                class="form-input"
                v-model="classDialogForm.className"
                placeholder="请输入班级名称，支持汉字、数字、字母组合"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="班级编号："
              prop="classNum"
            >
              <el-input
                class="form-input"
                v-model="classDialogForm.classNum"
                placeholder="请输入班级编号"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="班主任："
            >
              <el-select
                class="form-input"
                v-model="classDialogForm.classTeacher"
                clearable
                placeholder="请选择班主任"
              >
                <el-option
                  v-for="item in classTeacherOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="排序：" prop="sort">
              <el-input
                class="form-input"
                v-model="classDialogForm.sort"
                placeholder="请输入排序"
                :maxlength="50"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="毕业班：" prop="isFinalClass">
              <el-switch v-model="classDialogForm.isFinalClass"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="状态：" prop="status">
              <el-switch v-model="classDialogForm.status"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="备注：">
              <el-input
                class="form-input"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                placeholder="请填写描述内容..."
                v-model="classDialogForm.description"
                resize="none"
                :maxlength="100"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose"
          >取 消</el-button
        >
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="升班设置"
      :visible.sync="classUpDialogVisible"
      :append-to-body="true"
      :destroy-on-close="true"
      @before-close="handleClassUpClose"
      @close="handleClassUpClose"
      width="50%"
    >
      <el-form
        class="dialog-form"
        :model="classUpDialogForm"
        ref="classUpDialogForm"
        :rules="rules"
        label-width="6.25vw"
      >
        <el-row>
          <el-col :span="24">
            <div class="class-dialog-title">{{ classUpDialogForm.className }}</div>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="所属组织："
            >
              <div class="form-text">{{ classUpDialogForm.organization }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="所属学校："
            >
              <div class="form-text">{{ classUpDialogForm.schoolName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="所在年级："
            >
              <div class="form-text">{{ classUpDialogForm.gradeName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="班级学生："
              prop="classStudents"
            >
              <el-checkbox :indeterminate="studentsIsIndeterminate" v-model="studentsCheckAll" @change="handleStudentsCheckAllChange">全选</el-checkbox>
              <div style="margin-bottom: 0.78125vw;"></div>
              <el-checkbox-group v-model="classUpDialogForm.classStudents" @change="handleStudentsCheckedChange">
                <el-checkbox v-for="item in studentOptions" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              class="form-choose-item"
              label="班级老师："
              prop="classTeachers"
            >
              <el-checkbox :indeterminate="teachersIsIndeterminate" v-model="teachersCheckAll" @change="handleTeachersCheckAllChange">全选</el-checkbox>
              <div style="margin-bottom: 0.78125vw;"></div>
              <el-checkbox-group v-model="classUpDialogForm.classTeachers" @change="handleTeachersCheckedChange">
                <el-checkbox v-for="item in teacherOptions" :label="item.id" :key="item.id">{{item.name}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              class="form-choose-item"
              label="年级："
              prop="chooseGrade"
            >
              <el-select
                class="form-input"
                v-model="classUpDialogForm.chooseGrade"
                clearable
                placeholder="请选择年级"
              >
                <el-option
                  v-for="item in gradeOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              class="form-choose-item"
              label="班级："
              prop="chooseClass"
            >
              <el-select
                class="form-input"
                v-model="classUpDialogForm.chooseClass"
                clearable
                placeholder="请选择班级"
              >
                <el-option
                  v-for="item in classOptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClassUpClose"
          >取 消</el-button
        >
        <el-button :loading="buttonloading" type="primary" @click="handleClassUpSubmit"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import LTable from "@/components/tableComponent/index.vue";
export default {
  name: "classManage",
  components: {
    LTable,
  },
  data() {
    return {
      pageLoading: false, //页面loading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, //分页信息
      pageFlag: false, //是否显示分页
      tableData: [], //表格数据
      tableLoading: false, //表格loading
      selectData: [], //选中数据
      columns: [
        {
          label: "所属组织",
          slotName: "organization",
        },
        {
          label: "所属学校",
          slotName: "schoolName",
        },
        {
          label: "年级名称",
          slotName: "gradeName",
        },
        {
          label: "班级名称",
          slotName: "className",
        },
        {
          label: "班主任",
          slotName: "classTeacher",
        },
        {
          label: "班级老师（数量）",
          slotName: "classTeacherNum",
        },
        {
          label: "毕业班",
          slotName: "isFinalClass",
        },
        {
          label: "排序",
          slotName: "sort",
        },
        {
          label: "状态",
          slotName: "status",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      searchForm: {
        organization: "",
        classSchool: "",
        classGrade: "",
        classInfo: "",
        isFinalClass: "",
      }, //搜索条件
      organizationOptions: [], //组织下拉
      statusOptions: [], //状态下拉
      schoolOptions: [], //学校下拉
      gradeOptions: [], //年级下拉
      classTeacherOptions: [], //班主任下拉

      classDialogTitle: "",
      classDialogVisible: false, //编辑年级的弹窗显隐标识
      classDialogForm: {
        organization: "",
        schoolName: "",
        gradeName: "",
        className: "",
        classNum: "",
        classTeacher: "",
        isFinalClass: true,
        sort: "",
        status: true,
        description: "",
      }, //编辑年级弹窗form
      rules: {
        organization: [
          { required: true, message: "请选择组织", trigger: "change" },
        ],
        schoolName: [
          { required: true, message: "请选择学校", trigger: "change" },
        ],
        gradeName: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        className: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        classNum: [
          { required: true, message: "请输入班级编号", trigger: "blur" },
        ],
        isFinalClass: [
          { required: true, message: "请选择是否毕业班", trigger: "change" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
      }, //表单规则
      buttonloading: false, //按钮loading

      classUpDialogVisible: false, //升班设置的弹窗显隐标识
      classUpDialogForm: {
        organization: "",
        schoolName: "",
        gradeName: "",
        className: "",
        classStudents: [],
        classTeachers: [],
        chooseGrade: "",
        chooseClass: "",
      }, //升班设置弹窗form

      studentsIsIndeterminate: false, //班级学生半选标识
      studentsCheckAll: false, //班级学生是否全选标识
      studentOptions: [], //学生选项

      teachersIsIndeterminate: false, //班级老师半选标识
      teachersCheckAll: false, //班级老师是否全选标识
      teacherOptions: [], //老师选项

      classOptions: [], //班级下拉
    };
  },
  methods: {
    //获取列表信息
    getData() {},
    //查询
    searchFun() {
      this.page.num = 1;
      this.getData();
    },
    //重置
    resetFun() {
      this.searchForm = {
        organization: "",
        classSchool: "",
        classGrade: "",
        classInfo: "",
        isFinalClass: "",
      };
      this.page.num = 1;
      this.getData();
    },
    //页码变化
    handleCurrentChange(data) {
      this.page.num = data;
      this.getData();
    },
    //每页条数变化
    handleSizeChange(data) {
      this.page.size = data;
      this.page.num = 1;
      this.getData();
    },
    //选中数据变化
    handleSelectionChange(arr) {
      console.log(arr);
    },
    //选中数据
    handleSelectData(selection, row) {
      console.log(selection);
      console.log(row);
    },
    //删除操作
    handleDel(row) {
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h(
            "span",
            {
              style:
                "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;",
            },
            "确定要删除该班级？"
          ),
          h(
            "div",
            {
              style:
                "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px",
            },
            "是否确定删除该班级，删除后，将无法恢复。"
          ),
        ]),
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          iconClass: "el-icon-question",
          type: "warning",
          customClass: "warningMessage",
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    //升班
    handleStepClass(row){
      this.classUpDialogVisible = true
      this.$nextTick(() => {
        this.studentOptions = []
        this.teacherOptions = []
        this.classOptions = []
      })
    },
    //新建班级
    handleAdd() {
      this.classDialogVisible = true;
      this.$nextTick(() => {
        this.classDialogTitle = "新建班级"
      })
    },
    //编辑年级
    handleEdit(row) {
      this.classDialogVisible = true;
      this.$nextTick(() => {
        this.classDialogForm.organization = row.organization
        this.classDialogForm.schoolName = row.schoolName
        this.classDialogForm.gradeName = row.gradeName
        this.classDialogForm.className = row.className
        this.classDialogForm.classNum = row.classNum
        this.classDialogForm.classTeacher = row.classTeacher
        this.classDialogForm.isFinalClass = row.isFinalClass === '1' ? true : false
        this.classDialogForm.sort = row.sort
        this.classDialogForm.status = row.status === '1' ? true : false
        this.classDialogForm.description = row.description
        this.classDialogTitle = "编辑年级"
      })
    },
    //同步
    handleAsync() {},
    //弹窗关闭
    handleClose() {
      this.classDialogVisible = false;
      this.$nextTick(() => {
        this.classDialogForm = {
          organization: "",
          schoolName: "",
          gradeName: "",
          className: "",
          classNum: "",
          classTeacher: "",
          isFinalClass: true,
          sort: "",
          status: true,
          description: "",
        };
        this.buttonloading = false;
        this.classDialogTitle = ""
      });
    },
    //提交操作
    handleSubmit() {
      this.$refs.classDialogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            organization: this.classDialogForm.organization,
            schoolName: this.classDialogForm.schoolName,
            gradeName: this.classDialogForm.gradeName,
            className: this.classDialogForm.className,
            classNum: this.classDialogForm.classNum,
            classTeacher: this.classDialogForm.classTeacher,
            isFinalClass: this.classDialogForm.isFinalClass ? "1" : "0",
            sort: this.classDialogForm.sort,
            status: this.classDialogForm.status ? "1" : "0",
            description: this.classDialogForm.description,
          };
          let url = "";
          if (this.handleType == "add") {
            url = "/api/educourse/save";
          } else {
            params.id = this.courseId;
            url = "/api/educourse";
          }
          // this.$api.courseHandle(url, params, this.handleType).then((res) => {
          //   if (res.data.code === 0) {
          //     this.$message({
          //       message: "保存成功",
          //       type: "success",
          //       duration: 2000,
          //       onClose: () => {
          //         this.buttonloading = false;
          //         if (this.handleType == "add") {
          //           this.courseSettingDialogVisible = true;
          //           this.$nextTick(() => {
          //             this.courseId = res.data.data;
          //             this.courseSettingType = "introduce";
          //           });
          //         } else {
          //           this.pageLoading = true;
          //           this.getData();
          //         }
          //         this.handleClose();
          //       },
          //     });
          //   } else {
          //     this.buttonloading = false;
          //     this.$message.error(res.data.msg);
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //升班设置弹窗关闭
    handleClassUpClose(){
      this.classUpDialogVisible = false
      this.$nextTick(() => {
        this.classUpDialogForm = {
          organization: "",
          schoolName: "",
          gradeName: "",
          className: "",
          classStudents: [],
          classTeachers: [],
          chooseGrade: "",
          chooseClass: "",
        }
        this.studentsIsIndeterminate = false
        this.studentsCheckAll = false
        this.studentOptions = []

        this.teachersIsIndeterminate = false
        this.teachersCheckAll = false
        this.teacherOptions = []

        this.classOptions = []
        this.buttonloading = false;
      })
    },
    //升班设置弹窗提交
    handleClassUpSubmit(){
      this.$refs.classUpDialogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            organization: this.classUpDialogForm.organization,
            schoolName: this.classUpDialogForm.schoolName,
            gradeName: this.classUpDialogForm.gradeName,
            className: this.classUpDialogForm.className,
            classStudents: this.classUpDialogForm.classStudents,
            classTeachers: this.classUpDialogForm.classTeachers,
            chooseGrade: this.classUpDialogForm.chooseGrade,
            chooseClass: this.classUpDialogForm.chooseClass
          };
          let url = "";
          if (this.handleType == "add") {
            url = "/api/educourse/save";
          } else {
            params.id = this.courseId;
            url = "/api/educourse";
          }
          // this.$api.courseHandle(url, params, this.handleType).then((res) => {
          //   if (res.data.code === 0) {
          //     this.$message({
          //       message: "保存成功",
          //       type: "success",
          //       duration: 2000,
          //       onClose: () => {
          //         this.buttonloading = false;
          //         if (this.handleType == "add") {
          //           this.courseSettingDialogVisible = true;
          //           this.$nextTick(() => {
          //             this.courseId = res.data.data;
          //             this.courseSettingType = "introduce";
          //           });
          //         } else {
          //           this.pageLoading = true;
          //           this.getData();
          //         }
          //         this.handleClose();
          //       },
          //     });
          //   } else {
          //     this.buttonloading = false;
          //     this.$message.error(res.data.msg);
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //班级学生全选变化事件
    handleStudentsCheckAllChange(val) {
        this.classUpDialogForm.classStudents = val ? JSON.parse(JSON.stringify(this.studentOptions)) : [];
        this.studentsIsIndeterminate = false;
    },
    //班级学生选择变化事件
    handleStudentsCheckedChange(value) {
      let checkedCount = value.length;
      this.studentsCheckAll = checkedCount === this.studentOptions.length;
      this.studentsIsIndeterminate = checkedCount > 0 && checkedCount < this.studentOptions.length;
    },
    //班级老师全选变化事件
    handleTeachersCheckAllChange(val) {
        this.classUpDialogForm.classTeachers = val ? JSON.parse(JSON.stringify(this.teacherOptions)) : [];
        this.teachersIsIndeterminate = false;
    },
    //班级老师选择变化事件
    handleTeachersCheckedChange(value) {
      let checkedCount = value.length;
      this.teachersCheckAll = checkedCount === this.teacherOptions.length;
      this.teachersIsIndeterminate = checkedCount > 0 && checkedCount < this.teacherOptions.length;
    }
  },
};
</script>
<style scoped lang="scss">
.class-manage {
  padding: 30px 25px 20px 25px;
  background-color: #ffffff;
  position: relative;
  .class-manage-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.5019607843137255);
    .text {
      width: 100%;
      height: 100%;
      margin-left: 24px;
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
}
/deep/.el-input__count {
  &::before {
    content: "已输入 ";
  }
}
</style>